import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// project imports
import useAuth from 'hooks/useAuth';
import { DASHBOARD_PATH } from 'config';

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
    const { isLoggedIn, logout } = useAuth();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const handleUseEffect = async () => {
        // if (isLoggedIn && pathname === '/appsumo') {
        //     // console.log('logout();', pathname);
        //     await logout();
        // }
        if (isLoggedIn && window.location.pathname !== '/email-verified') {
            navigate(DASHBOARD_PATH, { replace: true });
        } else if (isLoggedIn) {
            navigate(pathname, { replace: true });
        }
    };
    useEffect(() => {
        handleUseEffect();
    }, [isLoggedIn, navigate]);

    return children;
};

GuestGuard.propTypes = {
    children: PropTypes.node
};

export default GuestGuard;
