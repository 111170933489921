import { lazy } from 'react';

// project imports
import GuestGuard from 'utils/route-guard/GuestGuard';
import MinimalLayout from 'layout/MinimalLayout';
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';
import { LOGIN_PATH, APPSUMO_PATH, REGISTER_PATH, FORGOT_PASSWORD_PATH, EMAIL_VERIFIED_PATH } from 'config';

const Login3 = Loadable(lazy(() => import('views/pages/authentication3/Login3')));
const AppSumo = Loadable(lazy(() => import('views/pages/authentication3/AppSumo')));
const RegisterWithOTP = Loadable(lazy(() => import('views/pages/authentication3/RegisterWithOTP')));
const ForgetPassword = Loadable(lazy(() => import('views/pages/authentication3/ForgotPassword3')));
const EmailVerified = Loadable(lazy(() => import('views/pages/authentication3/EmailVerified')));
// const NotFound = Loadable(lazy(() => import('views/pages/maintenance/Error')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
    path: '/',
    element: (
        <NavMotion>
            <GuestGuard>
                <MinimalLayout />
            </GuestGuard>
        </NavMotion>
    ),
    children: [
        {
            path: APPSUMO_PATH,
            element: <AppSumo />
        },
        {
            path: LOGIN_PATH,
            element: <Login3 />
        },
        {
            path: REGISTER_PATH,
            element: <RegisterWithOTP />
        },
        {
            path: FORGOT_PASSWORD_PATH,
            element: <ForgetPassword />
        },
        {
            path: EMAIL_VERIFIED_PATH,
            element: <EmailVerified />
        }
    ]
};

export default LoginRoutes;
